import React from "react";

import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';

export function Home() {
  return (
    <div className="home">
      <Image fluid src="https://placekitten.com/1920/1080"/>
      <h1> PITGANART </h1>
      </div>
  );
}

export default Home;
