import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import {LinkContainer} from 'react-router-bootstrap';

export function Menu() {
  return(
    <Navbar expand="lg">
      <LinkContainer to="/">
        <Navbar.Brand> PITGANART </Navbar.Brand>
        </LinkContainer>
      <Navbar.Toggle aria-controls="basic-navbar-nav"/>
      <Navbar.Collapse>
        <Nav className="me-auto">
          <NavDropdown title="ink" id="ink">
            <LinkContainer to="/inkvs"> 
              <NavDropdown.Item>Ink Volume S.</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
          <NavDropdown title="illustration" id="illustration">
            <LinkContainer to="/portraitpaintings"> 
              <NavDropdown.Item>Portrait Paintings</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
  );
}

export default Menu;
