import React from 'react';
import { 
  createBrowserRouter,
  Outlet,
  RouterProvider,
} from 'react-router-dom';
import Container from 'react-bootstrap/Container';

import './App.scss';

import Gallery from './Gallery.jsx';
import GalleryImage from './GalleryImage.jsx';
import Home from './Home.jsx';
import Menu from './Menu.jsx';

function Header() {
  return (
    <header>
      <Menu/> 
      </header>
  );
}

function Footer() {
  return (
    <footer>
      <h1> &copy; 2020-2021 Enrique Gan </h1>
    </footer>
  );
}

function Root() {
  return(
    <Container className="app" fluid>
      <Header/>
      <Outlet/>
      <Footer/>
    </Container>
  )
}

export function App() {
    const router = createBrowserRouter([ 
    {
      path: "/",
      element: <Root/>,
      children: [
        {
          path:"",
          element: <Home/>,
        },
        {
          path:":galleryId",
          element: <Gallery/>,
          loader: async ({params}) => {
            return fetch(`api.pitganart.com/galleries/${params.galleryId}.json`);
          },
        },
        {
          path:":galleryId/:imageId",
          element: <GalleryImage/>,
        }
      ],
    },

  ],{
    // basename: "/comics"
  });

  return (
    <div>
    <RouterProvider router={router}></RouterProvider>
    </div>
  )

}

export default App;
